import PopupMarketingQuery from "../../query/content/PopupMarketing";
const TAGS = ["BLOCK_POPUP_MARKETING"];

export default async function getMarketingPopup(today: string, pageId: string) {
  const cacheKey = `BLOCK_POPUP_MARKETING_${today}_${pageId}`;
  const nuxtApp = useNuxtApp();
  const { client } = useApolloClient();
  const { data: popupMarketing } = await useAsyncData(cacheKey, async () => {
    const { value, addToCache } = await useDataCache(cacheKey)
    if (value) {
      return value
    }
    const runtimeConfig = useRuntimeConfig();
    const { data: response } = await client.query({query: PopupMarketingQuery(today, pageId, runtimeConfig.public.langcode)});
    TAGS.push(cacheKey);
    await addToCache(response, TAGS);
    return response;
  })
  return {data: popupMarketing.value };
}
