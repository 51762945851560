import { MenuQuery } from "../../query/menu/NavigationMainFrench";
import type { MenuItems } from "~/interfaces/menu";

export default async function getMenu(menuCode: string) {
  const TAGS = [];
  const nuxtApp = useNuxtApp();
  const { client } = useApolloClient();
  const { data: menuItems } = await useAsyncData(menuCode, async () => {
    try { 
      const { value, addToCache } = await useDataCache(menuCode)
      if (value) {
        return value
      }
      const { data: response } = await client.query<MenuItems>({query: MenuQuery, variables:{ menu: menuCode }});
      TAGS.push(`menu:${menuCode}`);
        await addToCache(response, TAGS);  
      return response;
    } catch (err) {  
      console.error(err);  
    }  
  })
  return {data: menuItems.value };
}
