import { gql } from "graphql-tag";

export default function PopupMarketingQuery(date: String, pageId: String, langcode: String) {
  const gqlConcat = gql`
    query MyQuery {
      entityQuery(
        entityType: BLOCK_CONTENT
        filter: {
          groups: {
            conditions: {
              field: "langcode", 
              value: "${langcode}"
            }, 
            groups: {
              conditions: {
                field: "field_date_begin"
                operator: SMALLER_THAN_OR_EQUAL
                value: "${date}"
              }
              groups: {
                conditions: {
                  field: "field_date_end"
                  operator: GREATER_THAN_OR_EQUAL
                  value: "${date}"
                }
                groups: {
                  conditions: { field: "type", value: "block_popup_marketing" }
                  groups: {
                    conditions: {
                      field: "field_page"
                      operator: IN
                      value: "${pageId}"
                    },
                    conjunction: OR, 
                    groups: {
                      conditions: {
                        field: "field_all_pages", 
                        value: "1"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      ) {
        items {
          ... on BlockContentBlockPopupMarketing {
            id
            type: entityBundle
            fieldTitle
            fieldSubtitle
            fieldBody
            fieldImage {
              id: targetId
              alt
              entity {
                id
                uriRawField {
                  list {
                    url
                  }
                }
              }
            }
            fieldLink {
              title
              uri {
                path
              }
            }
            fieldPage {
              id
            }
            fieldDateBegin {
              value
            }
            fieldDateEnd {
              value
            }
          }
        }
      }
    }
  `;
  return gqlConcat;
}
