<script setup lang="ts">
import getMenu from "~/composables/service/graphql/client/menu/index";
import DesktopNav from "~/components/menu/desk-menu/DesktopNav.vue";
import MobileNav from "~/components/menu/mobile-menu/MobileNav.vue";
import Footer from "~/components/footer/Footer.vue";
import getAlertPopup from "~/composables/service/graphql/client/popup/alert";
import getMarketingPopup from "~/composables/service/graphql/client/popup/marketing";
import getBreadcrumb from "~/composables/service/graphql/client/breadcrumb/index";
import { useBreadcrumbStore } from "~/stores/breadcrumb";

const runtimeConfig = useRuntimeConfig();
const nuxtApp = useNuxtApp();
const { data: menuItems } = await getMenu(
  `NAVIGATION_MAIN_${runtimeConfig.public.language}`
);
const { data: menuSecondaryItems } = await getMenu(
  `NAVIGATION_SECONDARY_${runtimeConfig.public.language}`
);
const { data: menuFooterItems } = await getMenu(
  `FOOTER_MAIN_${runtimeConfig.public.language}`
);
const { data: menuFooterSecondaryItems } = await getMenu(
  `FOOTER_SECONDARY_${runtimeConfig.public.language}`
);

const { data: menuSearchItems } = await getMenu(
  `NAVIGATION_SEARCH_${runtimeConfig.public.language}`
);


const route = useRoute();
const dateOfDay = new Date().toISOString().split("T")[0];
//observe route to display different header for home and other pages
const pageId =  computed(() => {
  return nuxtApp.payload.drupalRoutes.hasOwnProperty(route.path) ? nuxtApp.payload.drupalRoutes[route.path]?.name?.toString()! : route.name?.toString()!;
});
const homePageId = runtimeConfig.public.homePageId;




const breadcrumbStore = useBreadcrumbStore();

const breadcrumbItems = ref<BreadcrumbItem[] | null>(null);

watch(pageId, async () => {
  breadcrumbItems.value = await fetchData();
  breadcrumbStore.setBreadcrumb(breadcrumbItems.value);
  console.log(breadcrumbItems.value);
});

const searchTitle = (elements: BreadcrumbItem[], title: string) => (elements.findIndex(((element)=>element.title == title)))
const fetchData = async () => {
  try {
    const { data } = await getBreadcrumb(`node/${pageId.value}`);
    if (data.value?.route?.breadcrumb) {
      return data.value.route.breadcrumb.filter((element: BreadcrumbItem, pos: number, self: BreadcrumbItem[]) => pos == searchTitle(self, element.title ));
    }
  } catch (error) {
    console.error("Error fetching breadcrumb:", error);
  }
};
breadcrumbItems.value = await fetchData();
breadcrumbStore.setBreadcrumb(breadcrumbItems.value);



const { data: popupAlertItems } = await getAlertPopup(dateOfDay);
const { data: popupMarketingItems } = await getMarketingPopup(
  dateOfDay,
  pageId.value
);
</script>

<template>
  <header
    class="w-full overflow-y-auto lg:h-fit lg:overflow-y-visible"
    :class="pageId === homePageId ? 'absolute top-0 z-20 is-homepage' : ''"
  >
    <ClientOnly>
      <PopupHomeBannerAlert
        v-if="popupAlertItems.entityQuery.items.length > 0"
        :popupItems="popupAlertItems"
      />
    </ClientOnly>
    <RenderCacheable :cache-tags="[`lang:${runtimeConfig.public.langcode}`,`menu:NAVIGATION_MAIN_${runtimeConfig.public.language}`, `menu:NAVIGATION_SECONDARY_${runtimeConfig.public.language}`, `menu:NAVIGATION_SEARCH_${runtimeConfig.public.language}`]" :cache-key="['DESKTOP_NAVIGATION_MAIN', runtimeConfig.public.langcode, pageId === homePageId ?  'HOME' : 'OTHER'].join('--')">
      <DesktopNav
        :menuItems="menuItems.menuByName.links"
        :menuSecondaryItems="menuSecondaryItems.menuByName.links"
        :menuSearchItems="menuSearchItems.menuByName?.links"
        class="hidden lg:block"
      />
    </RenderCacheable>
    <RenderCacheable :cache-tags="[`lang:${runtimeConfig.public.langcode}`, `menu:NAVIGATION_MAIN_${runtimeConfig.public.language}`, `menu:NAVIGATION_SECONDARY_${runtimeConfig.public.language}`]" :cache-key="['MOBILE_NAVIGATION_MAIN', runtimeConfig.public.langcode].join('--')">
      <MobileNav class="lg:hidden" :menuItems="menuItems.menuByName.links" :menuSecondaryItems="menuSecondaryItems.menuByName.links" />
    </RenderCacheable>
  </header>
  <article class="relative">
    <div class="overlay"></div>
    <ClientOnly>
      <PopupModalCommunication
        v-if="popupMarketingItems.entityQuery.items.length > 0"
        :popupItems="popupMarketingItems.entityQuery.items"
      />
      <PopupModalAlert
        v-if="popupAlertItems.entityQuery.items.length > 0"
        :popupItems="popupAlertItems.entityQuery.items"
      />
    </ClientOnly>
    <RenderCacheable :async-data-keys="[`BREADCRUMB-${pageId}`]" v-if="pageId != homePageId" :cache-tags="[`page:${pageId}`,`breadcrumb:${pageId}`, `lang:${runtimeConfig.public.langcode}`]" :cache-key="[runtimeConfig.public.langcode, pageId].join('--')">
      <UiBreadCrumb :breadcrumbItems="breadcrumbItems"/>
    </RenderCacheable>
    <slot />
  </article>
  <RenderCacheable :cache-tags="[`lang:${runtimeConfig.public.langcode}`,  `menu:FOOTER_MAIN_${runtimeConfig.public.language}`]" :cache-key="[runtimeConfig.public.langcode].join('--')">
    <Footer
      :menuItems="menuFooterItems.menuByName.links"
      :menuSecondaryItems="menuFooterSecondaryItems.menuByName.links"
    />
  </RenderCacheable>
  <ClientOnly>
          <GtmDatalayer></GtmDatalayer>
    </ClientOnly>

</template>
