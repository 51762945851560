import { BreadcrumbQuery } from "../../query/breadcrumb/Breadcrumb";
import type { BreadcrumbItems } from "~/interfaces/breadcrumb";

export default async function getBreadcrumb(path: String) {
  const KEY = `BREADCRUMB-${path.split("/")[1]}`;
  const TAGS = [];
  const nuxtApp = useNuxtApp();
  const { client } = useApolloClient();
  const { data: breadcrumbItems } = await useAsyncData(KEY, async () => {
    try {  
      const { value, addToCache } = await useDataCache(KEY)
      if (value) {
        return value
      }
      const { data: response } = await client.query<BreadcrumbItems>({ query: BreadcrumbQuery, variables: { path } });
      TAGS.push(`breadcrumb:${path.split("/")[1]}`);
        await addToCache(response, TAGS);
        return response;
    } catch (err) {  
      console.error(err);  
    }
  })

  return {data: breadcrumbItems.value};
}
