<template>
</template>
<script setup lang="ts">
import { useBreadcrumbStore } from "~/stores/breadcrumb";
import { useGtmPush } from "~/composables/gtm/push";
const { gtmPush } = useGtmPush();
const config = useRuntimeConfig();
const { breadcrumb } = storeToRefs(useBreadcrumbStore());

onMounted(() => {
    push();
    watch(()=>breadcrumb.value,
    ()=>push()
    );
});

const getBreadcrumbItemTitle = (index:number) :string => {
    if(breadcrumb.value && breadcrumb.value[index]){
        return breadcrumb.value[index]?.title ?? '';
    }
    return "";
}

const push = () =>{
    const datalayerData = {
        event: "virtualPageView",
        environment: config.public.frontendHost,
        language: config.public.language,
        page_title: document.title,
        page_category_1: getBreadcrumbItemTitle(0),
        page_category_2: getBreadcrumbItemTitle(1),
        page_category_3: getBreadcrumbItemTitle(2),
        page_category_4: getBreadcrumbItemTitle(3),
        pageUrl: window.location.href
    };
    gtmPush(datalayerData)
 
}


</script>